import { ValueObject } from '../abstractions';
export class InstrumentSymbol extends ValueObject {
    value;
    static MAX_LENGTH = 64;
    static create(value) {
        if (value.length > this.MAX_LENGTH)
            throw new Error('Symbol is too long.');
        return new InstrumentSymbol(value);
    }
    constructor(value) {
        super();
        this.value = value;
    }
    get frontMonth() {
        const today = new Date();
        return ContactMonthHelper.getFrontMonthSymbol(today);
    }
}
export class ContactMonthHelper {
    static getFrontMonthSymbol(today) {
        // 使用Date物件的getFullYear()方法取得年份
        let year = today.getFullYear();
        // 取得今天是星期幾（0 代表星期日，1 代表星期一，以此類推）
        const dayOfWeek = today.getDay();
        // 取得今天是當天的第幾小時
        const hour = today.getHours();
        // 取得今天是今月的第幾周
        const weekNumber = ContactMonthHelper.getTxWeekOfMonth(today);
        // 使用Date物件的getMonth()方法取得月份（注意：月份從0開始計算，所以需要加1）
        let month = today.getMonth() + 1;
        // 日期符合以下條件月份+1
        // 1. 本月第三周的星期三15:00後
        // 2. 本月第三周的星期四到星期日
        // 3. 本月第四周到月底
        if ((weekNumber === 3 && dayOfWeek === 3 && hour >= 15) ||
            (weekNumber === 3 && dayOfWeek >= 4) ||
            weekNumber > 3) {
            month++;
        }
        // 月份超過12月，則年份+1，月份設為1月
        if (month === 13) {
            month = 1;
            year++;
        }
        // 産品月份代碼	代表月份
        // A	1月
        // B	2月
        // C	3月
        // D	4月
        // E	5月
        // F	6月
        // G	7月
        // H	8月
        // I	9月
        // J	10月
        // K	11月
        // L	12月
        return String.fromCharCode(month + 64) + (year % 10);
    }
    static getTxWeekOfMonth(date) {
        const dayOfMonth = date.getDate();
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const firstDayOfWeek = firstDayOfMonth.getDay();
        // 計算第一週有幾天
        const daysInFirstWeek = 7 - firstDayOfWeek;
        let weekNumber = Math.ceil((dayOfMonth - daysInFirstWeek) / 7);
        // 計算今天是當月第幾週
        // 如果該月的第一週的開始為星期三之前，則表示第一週已經開始了，因此進行計算時需要將第一週的天數考慮在內；
        // 否則，第一週仍然屬於上個月，所以不需要考慮。
        daysInFirstWeek >= 4 ? (weekNumber += 1) : weekNumber;
        return weekNumber;
    }
}
