import { Enumeration } from '../abstractions';
import { MarketHours } from './market-hours';
export class Exchange extends Enumeration {
    static Unknown;
    static TWSE;
    static TFE;
    static NYMEX;
    static COMEX;
    static IUS;
    static CBT;
    static CME;
    static SGX;
    static HKF;
    static EUREX;
    static Binance;
    static NASDAQ;
    static NYSE;
    static DJ;
    static RUSSEL;
    static SP;
    static CBOE;
    static CTrader;
    constructor(name, value) {
        super(name, value);
    }
}
class UnknownExchange extends Exchange {
    get hours() {
        return [MarketHours.Undefined];
    }
    constructor() {
        super('Unknown', 0);
    }
}
class TwseExchange extends Exchange {
    get hours() {
        const marketHours = [MarketHours.TWSE];
        return marketHours;
    }
    constructor() {
        super('TWSE', 1);
    }
}
class TfeExchange extends Exchange {
    get hours() {
        const marketHours = [
            MarketHours.TFE_Standard,
            MarketHours.TFE_AM,
            MarketHours.TFE_PM,
            MarketHours.TFE_SF_AMPM,
        ];
        return marketHours;
    }
    constructor() {
        super('TFE', 2);
    }
}
class NymexExchange extends Exchange {
    get hours() {
        return [MarketHours.CME];
    }
    constructor() {
        super('NYM', 3);
    }
}
class ComexExchange extends Exchange {
    get hours() {
        return [MarketHours.CME];
    }
    constructor() {
        super('CMX', 4);
    }
}
class IusExchange extends Exchange {
    get hours() {
        return [MarketHours.IUS];
    }
    constructor() {
        super('IUS', 5);
    }
}
class CbtExchange extends Exchange {
    get hours() {
        return [MarketHours.CBT, MarketHours.CBT_Agricultural];
    }
    constructor() {
        super('CBT', 6);
    }
}
class CmeExchange extends Exchange {
    get hours() {
        return [MarketHours.CME];
    }
    constructor() {
        super('CME', 7);
    }
}
class SgxExchange extends Exchange {
    get hours() {
        return [MarketHours.SGX_CN];
    }
    constructor() {
        super('SGX', 8);
    }
}
class HkfExchange extends Exchange {
    get hours() {
        return [MarketHours.HKF];
    }
    constructor() {
        super('HKF', 9);
    }
}
class EurexExchange extends Exchange {
    get hours() {
        return [MarketHours.EUREX];
    }
    constructor() {
        super('URX', 10);
    }
}
class BinanceExchange extends Exchange {
    get hours() {
        return [MarketHours.Crypto];
    }
    constructor() {
        super('Binance', 11);
    }
}
class NasdaqExchange extends Exchange {
    get hours() {
        return [MarketHours.USStocks];
    }
    constructor() {
        super('NASDAQ', 12);
    }
}
class NyseExchange extends Exchange {
    get hours() {
        return [MarketHours.USStocks];
    }
    constructor() {
        super('NYSE', 13);
    }
}
class DjExchange extends Exchange {
    get hours() {
        return [MarketHours.Undefined];
    }
    constructor() {
        super('DJ', 14);
    }
}
class RusselExchange extends Exchange {
    get hours() {
        return [MarketHours.Undefined];
    }
    constructor() {
        super('RUSSEL', 15);
    }
}
class SpExchange extends Exchange {
    get hours() {
        return [MarketHours.Undefined];
    }
    constructor() {
        super('SP', 16);
    }
}
class CboeExchange extends Exchange {
    get hours() {
        return [MarketHours.Undefined];
    }
    constructor() {
        super('CBOE', 17);
    }
}
class CTraderExchange extends Exchange {
    get hours() {
        return [MarketHours.Undefined];
    }
    constructor() {
        super('CTrader', 18);
    }
}
Exchange.Unknown = new UnknownExchange();
Exchange.TWSE = new TwseExchange();
Exchange.TFE = new TfeExchange();
Exchange.NYMEX = new NymexExchange();
Exchange.COMEX = new ComexExchange();
Exchange.IUS = new IusExchange();
Exchange.CBT = new CbtExchange();
Exchange.CME = new CmeExchange();
Exchange.SGX = new SgxExchange();
Exchange.HKF = new HkfExchange();
Exchange.EUREX = new EurexExchange();
Exchange.Binance = new BinanceExchange();
Exchange.NYSE = new NyseExchange();
Exchange.DJ = new DjExchange();
Exchange.RUSSEL = new RusselExchange();
Exchange.SP = new SpExchange();
Exchange.CBOE = new CboeExchange();
Exchange.NASDAQ = new NasdaqExchange();
Exchange.CTrader = new CTraderExchange();
